import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";

export type DeletionModalProps = {
  open: boolean;
  onDialogClose: () => void;
  performDelete: () => void;
};

const DeletionModal = (props: DeletionModalProps) => {
  return (
    <Dialog open={props.open} onClose={props.onDialogClose}>
      <DialogTitle>Suppression d'un message</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          Voulez-vous vraiment supprimer ce message ? Cette action est
          irréversible.
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={props.onDialogClose}>Annuler</Button>
        <Button onClick={props.performDelete} style={{ color: "red" }}>
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletionModal;
