import axios from "axios";
import { Room } from "../types";
import { MessageType } from "../types";

class MessageService {
  private readonly url = `${process.env.REACT_APP_API_URL}`;

  async list(username: string) {
    return await axios.get(this.url, { headers: { username } });
  }

  async getRooms() {
    return await axios.get<Room[]>(`${this.url}/room`);
  }

  async getMessages(roomId: string) {
    return await axios.get<MessageType[]>(`${this.url}/message/${roomId}`);
  }
  async send(
    roomId: string,
    messageId: string,
    currentMessage: string,
    username: string
  ) {
    await axios.post(
      `${this.url}/message/${roomId}`,
      {
        messageId,
        text: currentMessage,
      },
      { headers: { username } }
    );
  }

  async edit(messageId: string, newMessage: string, username: string) {
    await axios.put(
      `${this.url}/message/${messageId}`,
      {
        text: newMessage,
      },
      { headers: { username } }
    );
  }

  async delete(messageId: string, username: string) {
    await axios.delete(`${this.url}/message/${messageId}`, {
      headers: { username },
    });
  }
}

const messageService = new MessageService();
export default messageService;
