import {
  Container,
  Divider,
  Paper,
  Theme,
  ThemeOptions,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { useRef, useState } from "react";

import { MessageProps } from "../types";
import MessageMenu from "./MessageMenu";

function Message(props: MessageProps) {
  let innerPalette: ThemeOptions["palette"];
  if (props.colorMode === "dark") {
    innerPalette = {
      background: {
        paper: props.self ? "rgba(43, 6, 108, 0.8)" : "rgba(48, 81, 115, 0.8)",
      },
    };
  } else {
    innerPalette = {
      background: {
        paper: props.self
          ? "rgba(230, 185, 250, 0.8)"
          : "rgba(82, 162, 246, 0.8)",
      },
    };
  }
  const messageTheme: ThemeOptions = {
    palette: innerPalette,
    components: {
      MuiContainer: {
        defaultProps: {
          style: {
            display: "flex",
            flexDirection: props.self ? "row-reverse" : "row",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            maxWidth: "75%",
            padding: "8px",
            margin: "8px",
          },
        },
      },
    },
  };

  // message menu management
  const [openMenu, setOpenMenu] = useState(false);
  const anchor = useRef<null | HTMLDivElement>(null);
  const openMessageMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setOpenMenu(true);
  };

  return (
    <ThemeProvider theme={(theme: Theme) => createTheme(theme, messageTheme)}>
      <Container maxWidth="lg" onContextMenu={openMessageMenu}>
        {!props.deleted ? (
          <Paper elevation={2} ref={anchor}>
            <Typography variant="body2">{props.author}</Typography>
            <Divider variant="middle" />
            <Typography variant="body1">{props.content}</Typography>
            {props.edited && (
              <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                <Typography variant="caption">Edité</Typography>
              </div>
            )}
          </Paper>
        ) : (
          <Paper elevation={2} ref={anchor}>
            <Typography variant="body2">{props.author}</Typography>
            <Divider variant="middle" />
            <Typography variant="caption">Ce message a été supprimé</Typography>
          </Paper>
        )}
      </Container>
      {props.self && !props.deleted && (
        <MessageMenu
          open={openMenu}
          onClose={() => setOpenMenu(false)}
          anchorEl={anchor.current}
          onEditClick={props.editMessage}
          onDeleteClick={props.deleteMessage}
        />
      )}
    </ThemeProvider>
  );
}
export default Message;
