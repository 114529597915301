import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LinkIcon from "@mui/icons-material/Link";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useState } from "react";
import { Room } from "../types";
import BrightnessHighIcon from "@mui/icons-material/BrightnessHigh";
import Brightness3Icon from "@mui/icons-material/Brightness3";

type HeaderProps = {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  username: string;
  rooms: Room[] | undefined;
  currentRoom: Room | undefined;
  setCurrentRoom: React.Dispatch<React.SetStateAction<Room | undefined>>;
  setColorMode: React.Dispatch<React.SetStateAction<"light" | "dark">>;
  colorMode: "light" | "dark";
};
function Header(props: HeaderProps) {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <Box sx={{ position: "sticky", top: 0 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setOpenDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {props.currentRoom?.name}
          </Typography>
          <Button color="inherit">{props.username}</Button>
          <Switch
            onClick={() => {
              props.setColorMode((theme) =>
                theme === "light" ? "dark" : "light"
              );
            }}
            checkedIcon={<Brightness3Icon sx={{ marginTop: "-5%" }} />}
            icon={
              <BrightnessHighIcon sx={{ color: "#ffc107", marginTop: "-5%" }} />
            }
            checked={props.colorMode === "dark"}
            size="medium"
          />
        </Toolbar>
      </AppBar>
      <Drawer
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        <List>
          {props.rooms &&
            props.rooms.map((room) => (
              <ListItem key={room.roomId} disablePadding>
                <ListItemButton
                  onClick={(event) => {
                    props.setCurrentRoom(room);
                    setOpenDrawer(false);
                  }}
                >
                  <ListItemIcon>
                    <LinkIcon />
                  </ListItemIcon>
                  <ListItemText primary={room.name} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => props.setOpenModal(true)}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary={"Profil"} />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}

export default Header;
