import {
  Button,
  Container,
  CssBaseline,
  Dialog,
  Paper,
  TextField,
  Theme,
  ThemeOptions,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { v4 } from "uuid";
import "./App.css";

import DoneIcon from "@mui/icons-material/Done";
import Header from "./component/Header";
import SendIcon from "@mui/icons-material/Send";
import Message from "./component/Message";
import { Room } from "./types";

import "./App.css";
import DeletionModal from "./component/DeletionModal";
import messageService from "./services/message.service";
import { socket } from "./socket";
import type { MessageType, Messages } from "./types";
import { EventReceived } from "./websockets/events-contract";
import { eventsReceivedHandler } from "./websockets/eventsReceivedHandler";
import { AxiosResponse } from "axios";

function App() {
  const [colorMode, setColorMode] = useState<"light" | "dark">("dark");
  const [theme, setTheme] = useState<Theme>(createTheme());
  const [openModal, setOpenModal] = useState(
    window.localStorage.getItem("username") === null
  );
  const [username, setUsername] = useState(
    window.localStorage.getItem("username") ?? ""
  );
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [currentRoom, setCurrentRoom] = useState<Room>();
  const [messages, setMessages] = useState<Messages>({});
  const [currentMessage, setCurrentMessage] = useState("");
  const [currentEditedMessage, setCurrentEditedMessage] = useState("");
  const [rooms, setRooms] = useState<Room[]>();
  const lastMessageRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    // global MUI style
    const palette: ThemeOptions["palette"] = {
      mode: colorMode,
      primary: {
        main:
          colorMode === "dark" ? "rgba(240,240,240,0.8)" : "rgba(30,30,30,0.8)",
      },
      secondary: {
        main: "#fadd68",
        contrastText: "#1769aa",
      },
      background: {
        default: colorMode === "dark" ? "#0a1929" : "#eeeeee",
        paper: colorMode === "dark" ? "#0a1929" : "#eeeeee",
      },
      text: {
        primary:
          colorMode === "dark" ? "rgba(240,240,240,0.8)" : "rgba(30,30,30,0.8)",
        secondary:
          colorMode === "dark"
            ? "rgba(240,240,240,0.58)"
            : "rgba(30,30,30,0.58)",
        disabled:
          colorMode === "dark" ? "rgba(240,240,240,0.4)" : "rgba(30,30,30,0.4)",
      },
    };

    const themeOptions: ThemeOptions = {
      palette,
      typography: {
        h1: {
          fontWeight: 400,
          fontSize: "2.5rem",
        },
        h2: {
          fontWeight: 300,
          fontSize: "2rem",
        },
        h3: {
          fontWeight: 250,
          fontSize: "1.5rem",
        },
        body1: {
          fontSize: "1rem",
        },
        body2: {
          fontSize: "0.8rem",
          fontWeight: "lighter",
        },
        allVariants: {
          textAlign: "left",
          fontFamily: "Roboto",
          // marginLeft: "8px",
        },
      },
      shape: {
        borderRadius: 10,
      },
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              padding: "8px",
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: { background: colorMode === "dark" ? "#0a1929" : "#fefefe" },
          },
        },
      },
    };
    setTheme(createTheme(themeOptions));
  }, [colorMode]);

  useEffect(() => {
    socket.connect();

    socket.on("connect", () => setIsConnected(true));
    socket.on("disconnect", () => setIsConnected(false));

    socket.on(EventReceived.NEW, (message: MessageType) => {
      eventsReceivedHandler[EventReceived.NEW](message, setMessages);
    });
    socket.on(EventReceived.EDITED, (message: MessageType) =>
      eventsReceivedHandler[EventReceived.EDITED](message, setMessages)
    );
    socket.on(EventReceived.DELETED, (message: MessageType) =>
      eventsReceivedHandler[EventReceived.DELETED](message, setMessages)
    );

    return () => {
      socket.off("connect", () => setIsConnected(true));
      socket.off("disconnect", () => setIsConnected(false));

      socket.off(EventReceived.NEW, (message: MessageType) => {
        eventsReceivedHandler[EventReceived.NEW](message, setMessages);
      });
      socket.off(EventReceived.EDITED, (message: MessageType) =>
        eventsReceivedHandler[EventReceived.EDITED](message, setMessages)
      );
      socket.off(EventReceived.DELETED, (message: MessageType) =>
        eventsReceivedHandler[EventReceived.DELETED](message, setMessages)
      );
    };
  }, []);

  const addMessage = useCallback(
    (messageId: string, content: string) => {
      if (currentRoom) {
        setMessages((prevMessages) => ({
          ...prevMessages,
          [currentRoom?.roomId]: {
            ...(prevMessages[currentRoom?.roomId] ?? []),
            messages: [
              ...(prevMessages[currentRoom?.roomId]?.messages ?? []),
              {
                messageId,
                date: new Date(),
                author: username,
                text: content,
                edited: false,
                deleted: false,
                roomId: currentRoom?.roomId,
                sent: false,
              },
            ],
          },
        }));
      }
    },
    [username, currentRoom]
  );

  useEffect(() => {
    lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [currentMessage]);

  useEffect(() => {
    window.localStorage.setItem("username", username);
  }, [username]);

  useEffect(() => {
    messageService.getRooms().then((room) => {
      setRooms(room.data);
      const localRoom = room.data.find((element) => {
        return element.roomId === window.localStorage.getItem("roomId");
      });
      if (localRoom) setCurrentRoom(localRoom);
      else setCurrentRoom(room.data[0]);
    });
  }, []);

  useEffect(() => {
    if (currentRoom) {
      window.localStorage.setItem("roomId", currentRoom.roomId);
      messageService
        .getMessages(currentRoom.roomId)
        .then((resp: AxiosResponse<MessageType[]>) => {
          resp.data.reverse();
          setMessages((prevMess) => ({
            ...prevMess,
            [currentRoom.roomId]: {
              id: currentRoom.roomId,
              name: currentRoom.name,
              messages: resp.data as MessageType[],
            },
          }));
        });
    }
  }, [currentRoom]);

  const editMessage = useCallback((newMessage: MessageType) => {
    setMessages((prevMessages) => ({
      ...prevMessages,
      [newMessage.roomId]: {
        ...(prevMessages[newMessage.roomId] ?? []),
        messages: (prevMessages[newMessage.roomId]?.messages ?? []).map(
          (message) =>
            message.messageId === newMessage.messageId ? newMessage : message
        ),
      },
    }));
  }, []);

  const [messageToEdit, setMessageToEdit] = useState<MessageType | null>(null);
  const [messageToDelete, setMessageToDelete] = useState<MessageType | null>(
    null
  );

  const validateMessage = useCallback(async () => {
    if (messageToEdit) {
      const newMessage = {
        ...messageToEdit,
        text: currentEditedMessage,
        edited: true,
        sent: false,
      };
      editMessage(newMessage);
      await messageService.edit(
        newMessage.messageId,
        currentEditedMessage,
        username
      );
      setCurrentEditedMessage("");
      setMessageToEdit(null);
    } else {
      const messageId = v4();
      addMessage(messageId, currentMessage);
      await messageService.send(
        currentRoom!.roomId,
        messageId,
        currentMessage,
        username
      );
      setCurrentMessage("");
    }
  }, [
    currentRoom,
    addMessage,
    currentEditedMessage,
    currentMessage,
    editMessage,
    messageToEdit,
    username,
  ]);

  const deleteMessage = useCallback(async () => {
    if (messageToDelete) {
      await messageService.delete(messageToDelete.messageId, username);
      setMessageToDelete(null);
    }
  }, [messageToDelete, username]);

  if (!isConnected) return null;

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header
          setOpenModal={setOpenModal}
          username={username}
          currentRoom={currentRoom}
          setCurrentRoom={setCurrentRoom}
          rooms={rooms}
          colorMode={colorMode}
          setColorMode={setColorMode}
        />
        <Dialog open={openModal}>
          <TextField
            onChange={(event) => setUsername(event.target.value)}
            placeholder="Entrez votre pseudo..."
            defaultValue={username}
            InputProps={{
              endAdornment: (
                <DoneIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpenModal((value) => !value)}
                />
              ),
            }}
          />
        </Dialog>
        <Container
          maxWidth="lg"
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "80vh",
          }}
        >
          {currentRoom &&
            (messages[currentRoom?.roomId]?.messages ?? []).map((message) => (
              <div ref={lastMessageRef} key={message.messageId}>
                <Message
                  author={message.author}
                  content={message.text}
                  self={message.author === username}
                  editMessage={() => {
                    setMessageToEdit(message);
                    setCurrentEditedMessage(message.text);
                  }}
                  deleteMessage={() => setMessageToDelete(message)}
                  edited={message.edited}
                  deleted={message.deleted}
                  colorMode={colorMode}
                />
              </div>
            ))}
          <Paper
            style={{ position: "sticky", bottom: "0", padding: "16px" }}
            elevation={0}
          ></Paper>
        </Container>
        <Container
          maxWidth="lg"
          sx={{ position: "sticky", bottom: 0, paddingBottom: "16px" }}
        >
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button
              onClick={() =>
                lastMessageRef.current?.scrollIntoView({ behavior: "smooth" })
              }
            >
              Aller au dernier message
            </Button>
          </div>
          <TextField
            placeholder="Commencez à taper ici..."
            multiline
            fullWidth
            value={messageToEdit?.text ? currentEditedMessage : currentMessage}
            onKeyDown={async (e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                validateMessage();
              }
            }}
            minRows={3}
            variant="outlined"
            onChange={(event) => {
              if (messageToEdit) setCurrentEditedMessage(event.target.value);
              else setCurrentMessage(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <SendIcon
                  style={{ cursor: "pointer" }}
                  onClick={validateMessage}
                />
              ),
            }}
          />
        </Container>

        <DeletionModal
          open={!!messageToDelete}
          onDialogClose={() => setMessageToDelete(null)}
          performDelete={deleteMessage}
        />
      </ThemeProvider>
    </div>
  );
}

export default App;
