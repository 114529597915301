import { MessageType, MessageSetter } from "../types";
import { EventReceived } from "./events-contract";

interface EventReceivedInterface {
  [EventReceived.NEW]: (
    message: MessageType,
    setMessage: MessageSetter
  ) => void;
  [EventReceived.EDITED]: (
    message: MessageType,
    setMessage: MessageSetter
  ) => void;
  [EventReceived.DELETED]: (
    message: MessageType,
    setMessage: MessageSetter
  ) => void;
}

export const eventsReceivedHandler: EventReceivedInterface = {
  [EventReceived.NEW]: (newMessage, setMessages) =>
    setMessages((prevMessages) => {
      if (
        prevMessages[newMessage.roomId]?.messages?.some(
          (message) => message.messageId === newMessage.messageId
        )
      ) {
        return {
          ...prevMessages,
          [newMessage.roomId]: {
            ...prevMessages[newMessage.roomId],
            messages: prevMessages[newMessage.roomId].messages.map((message) =>
              message.messageId === newMessage.messageId ? newMessage : message
            ),
          },
        };
      }
      return {
        ...prevMessages,
        [newMessage.roomId]: {
          ...prevMessages[newMessage.roomId],
          messages: [
            ...(prevMessages[newMessage.roomId]?.messages ?? []),
            newMessage,
          ],
        },
      };
    }),
  [EventReceived.EDITED]: (editedMessage, setMessages) =>
    setMessages((prevMessages) => {
      const messages = (prevMessages[editedMessage.roomId]?.messages ?? []).map(
        (message) =>
          message.messageId === editedMessage.messageId
            ? editedMessage
            : message
      );
      return {
        ...prevMessages,
        [editedMessage.roomId]: {
          ...prevMessages[editedMessage.roomId],
          messages,
        },
      };
    }),
  [EventReceived.DELETED]: (message, setMessages) =>
    setMessages((prevMessages) => {
      const messages = (prevMessages[message.roomId]?.messages ?? []).filter(
        (msg) => msg.messageId !== message.messageId
      );
      return {
        ...prevMessages,
        [message.roomId]: {
          ...prevMessages[message.roomId],
          messages,
        },
      };
    }),
};
