import { Delete, Edit } from "@mui/icons-material";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
  ThemeOptions,
  ThemeProvider,
  createTheme,
} from "@mui/material";

export type MessageMenuProps = {
  open: boolean;
  onClose: () => void;
  anchorEl: null | HTMLDivElement;
  onEditClick: () => void;
  onDeleteClick: () => void;
};

const Message = ({
  open,
  onClose,
  anchorEl,
  onEditClick,
  onDeleteClick,
}: MessageMenuProps) => {
  const messageTheme: ThemeOptions = {
    components: {
      MuiContainer: {
        defaultProps: {
          style: {
            display: "flex",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            maxWidth: "75%",
            padding: "8px",
            margin: "8px",
          },
        },
      },
    },
  };

  return (
    <ThemeProvider theme={(theme: Theme) => createTheme(theme, messageTheme)}>
      <Menu open={open} anchorEl={anchorEl} onClose={onClose}>
        <MenuItem
          onClick={() => {
            onEditClick();
            onClose();
          }}
        >
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>Modifier</ListItemText>
        </MenuItem>
        <MenuItem
          color="red"
          onClick={() => {
            onDeleteClick();
            onClose();
          }}
        >
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <ListItemText>Supprimer</ListItemText>
        </MenuItem>
      </Menu>
    </ThemeProvider>
  );
};

export default Message;
